// export all svg realtive path from one place

export const TRIPPLE_CHEVRON = "/images/svg/chevron.svg";

export const FARMER_ICON = "/images/svg/farmer.svg";
export const GRAINS_ICON = "/images/svg/grains.svg";
export const PYRAMIDS_ICON = "/images/svg/pyramids.svg";
export const WAREHOUSE_ICON = "/images/svg/warehouse.svg";
export const PERSON_ICON = "/images/svg/person.svg";
export const HECTARE_ICON = "/images/svg/hectares_icon.svg";
export const STORE_ICON = "/images/svg/store.svg";

export const NO_POVERTY_ICON_ONLY = "/images/svg/no_poverty_icon_only.svg";
export const ZERO_HUNGER_ICON_ONLY = "/images/svg/zero_hunger_icon_only.svg";
export const GENDER_EQUALITY_ICON_ONLY =
  "/images/svg/gender_equality_icon_only.svg";
export const DECENT_WORK_ICON_ONLY = "/images/svg/decent_work_icon_only.svg";
export const INDUSTRY_INNOVATION_INFRASTRUCTURE_ICON_ONLY =
  "/images/svg/industry_innovation_infrastructure_icon_only.svg";
export const RESPONSIBLE_CONSUMPTION_ICON_ONLY =
  "/images/svg/responsible_consumption_icon_only.svg";

export const PLACEHOLDER_VIDEO_THUMBNAIL = "/images/svg/suraju.svg";
export const PLAY_BUTTON = "/images/svg/play.svg";
export const FEMALE_FARMER = "/images/svg/female_farmer.svg";
export const INSTITUTION = "/images/svg/institution.svg";
export const BOWL = "/images/svg/bowl.svg";
export const INPUTICON = "/images/svg/input.svg";
export const FUNDERSICON = "/images/svg/funders.svg";
export const OFFTAKERSICON = "/images/svg/offtakers.svg";

export const KOBOICON = "/images/svg/kobo-icon.svg";
export const STERLINGICON = "/images/svg/sterling-icon.svg";
export const TAKLOGISTICSICON = "/images/svg/tak-logistics-icon.svg";
export const WACOTICON = "/images/svg/wacot-icon.svg";
export const WFPICON = "/images/svg/wfp-icon.svg";
export const CBNICON = "/images/svg/cbn-icon.svg";

export const SYNGENTA = "/images/svg/sygenta_logo.svg";
export const ROMOIL = "/images/svg/romoil_logo.svg";
export const PREMIER_FEEDS = "/images/svg/premier_feeds_logo.svg";
export const NOTORE = "/images/svg/notore_logo.svg";
export const KAMTRAC = "/images/svg/kamtrac_logo.svg";
export const INDORAMA = "/images/svg/indorama_logo.svg";
export const FMN = "/images/svg/FMN_logo.svg";
export const DIAGEO = "/images/svg/diageo_logo.svg";
export const USAID_TRADE = "/images/svg/usaid_trade_logo.svg";
export const AMO_BYNG = "/images/svg/amo_byng_logo.svg";
export const AFEX = "/images/svg/afex_logo.svg";
export const OCP = "/images/svg/ocp_logo.svg";
export const TECHNOSERVE = "/images/svg/technoserve_logo.svg";
export const LEADWAY = "/images/svg/leadway_logo.svg";

export const INTERACTIVE_HAND = "/images/svg/interactive_hand.svg";

export const FACEBOOK_ICON = "/images/svg/facebook-icon.svg";
export const TWITTER_ICON = "/images/svg/twitter-icon.svg";
export const INSTAGRAM_ICON = "/images/svg/instagram-icon.svg";
export const LINKEDIN_ICON = "/images/svg/linkedin-icon.svg";
export const YOUTUBE_ICON = "/images/svg/youtube-icon.svg";

export const EMAIL_ICON = "/images/svg/email.svg";
export const PHONE_ICON = "/images/svg/phone.svg";
export const FACEBOOK_CONTACT = "/images/svg/Facebook.svg";
export const TWITTER_CONTACT = "/images/svg/twitter.svg";
export const INSTAGRAM_CONTACT = "/images/svg/instagram.svg";
export const LINKEDIN_CONTACT = "/images/svg/linkedin.svg";
export const YOUTUBE_CONTACT = "/images/svg/youtube_contact.svg";

export const INPUT_ICON = "/images/svg/input_icon.svg";
export const DEVELOPMENT_ICON = "/images/svg/development_icon.svg";
export const TECHNOLOGY_ICON = "/images/svg/technology_icon.svg";
export const MARKET_ICON = "/images/svg/market_icon.svg";
export const POST_HARVEST_ICON = "/images/svg/post_harvest_icon.svg";
export const FIN_INCLUSION_ICON = "/images/svg/fin_inclusion_icon.svg";

export const PARTNER_ORBIT = "/images/svg/partner_orbit.svg";

export const ACCORDION_ACTIVE_ARROW = "/images/svg/active-arrow.svg";
export const ACCORDION_INACTIVE_ARROW = "/images/svg/unactive-arrow.svg";

export const LIGHT_LOGO_URI =
  "/images/svg/thrive-agric-logo-light-transparent.svg";
export const DARK_LOGO_URI =
  "/images/svg/thrive-agric-logo-dark-transparent.svg";
